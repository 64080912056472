<script setup lang="ts">
import SVGHeart from '@mop/shared/images/misc/header-heart.svg?component';
import SVGHeartFilled from '@mop/shared/images/misc/heart-filled.svg?component';
import SVGMopLogo from '@mop/shared/images/logo/logo_mop.svg?component';
import SVGMopLogoSignet from '@mop/shared/images/logo/logo_mop_signet.svg?component';
import type { ObserveScrollConfig, ActionPosition } from '@mop/types/index';
import JobBenefits from './JobBenefits.vue';
import JobAudio from './JobAudio.vue';
import type { Alternate, SeoBreadcrumb, CmsContentElementModel } from '@/types/cms';
import { LOCALE_MAP } from '@/i18n/localeList';
import SVGShare from '@/assets/icons/share.svg?component';

defineOptions({
  name: 'JobPost',
});

const { $gtm, $urls, $mopI18n, $storyblokLivePreview } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const classNameRef = ref(['']);
const loadingRef = ref({
  loading: true,
});
const { toggleWishlistItem, wishlistItemExists } = useMopWishlist();
const showShareRef = ref(false);
const { getCmsStory, trackPageView, cmsStoryModelRef } = useMopCms();
const { fetchJob, fetchAllJobs, jobItemRef, getJobIdFromPath } = useMopJob();
const { initTransition } = useMopPageTransitionClient();
const { getJobSchema, getHeadObject, getAlternatesFromStory, getBreadcrumbSchema, setAlternates } = useMopSeo();
// @ts-ignore
const jobId = getJobIdFromPath(route.params.jobId);
initTransition(classNameRef, loadingRef);
// @TODO: finish when ready
const fullPath = window.location.href;
const socialMediaLinksRef: Ref<{ [key: string]: string }> = ref({
  email: '',
  facebook: '',
  linkedin: '',
  twitter: '',
  whatsapp: '',
});
const isHeaderStickyRef = ref(false);
const isHeaderPrepareStickyRef = ref(false);

onMounted(async () => {
  await Promise.all([fetchJob(jobId), fetchAllJobs()]);

  if (!jobItemRef.value.getId() && !$storyblokLivePreview.isEnabledInIframe) {
    router.replace($mopI18n.localePath($urls.CAREER_PAGE));
    return;
  } else {
    socialMediaLinksRef.value = getMediaSiteLinks({
      url: fullPath,
      title: jobItemRef.value.getTitle(),
    });
    const language = jobId === '2023-2613' ? 'en' : LOCALE_MAP[jobItemRef.value.getCountryId()];
    await getCmsStory('/career/start-creating-with-us/our-jobs/vacancy', {
      language: language || 'default',
    });
    trackPageView('Career');
    $gtm.trackEvent({
      eventName: 'view_item',
      customValue: {
        items: [jobItemRef.value.getGtmTrackData()],
      },
    });
  }

  const jobItemPath = jobItemRef.value.getUrl();

  if (!route.path.includes(jobItemPath)) {
    await router.replace($mopI18n.localePath(`${$urls.CAREER_PAGE}/${jobItemPath}`));
  }

  loadingRef.value.loading = false;
});

const jobItemHeaderElements = computed(() => {
  const headerBlock = cmsStoryModelRef.value.getContentElements('header');
  let headerBannerElement;
  if (headerBlock.length === 0) {
    return [];
  }
  const divisionId = jobItemRef.value.getDivisionId();
  headerBannerElement = headerBlock.find((element) => element?.getData()?.divisionGroup?.includes(divisionId));
  const defaultHeaderBannerElement = headerBlock.find(
    (element) => element?.getData()?.divisionGroup?.includes('default'),
  );

  headerBannerElement ??= defaultHeaderBannerElement;
  return [headerBannerElement] as CmsContentElementModel[];
});

const metaData = computed(() => {
  const careerLink: string = $mopI18n.localePath($urls.CAREER);
  const startCreatingLink: string = $mopI18n.localePath($urls.CAREER_START_CREATING);
  const ourJobsLink: string = $mopI18n.localePath($urls.CAREER_PAGE);
  const title = jobItemRef.value.getTitle();
  const description = cmsStoryModelRef.value?.getSeo()?.description?.replace('{job-title}', title) || title;
  const isIndexable = true;
  const canonical = route.path;
  let alternates: Alternate[] = getAlternatesFromStory(cmsStoryModelRef.value);
  alternates = alternates.map((alternate: Alternate) => {
    const hrefSplit: string[] = alternate.href.split('/');
    hrefSplit.splice(-1);
    const href = `${hrefSplit.join('/')}/${jobItemRef.value.getUrl()}`;
    return {
      href,
      lang: alternate.lang,
    };
  });
  setAlternates(alternates);

  const breadcrumbs: SeoBreadcrumb[] = [
    {
      name: $mopI18n.t('locale.blog.name_career'),
      url: careerLink,
    },
    {
      name: $mopI18n.t('locale.jobs.start_creating_with_us'),
      url: startCreatingLink,
    },
    {
      name: $mopI18n.t('locale.jobs.search_button'),
      url: ourJobsLink,
    },
    {
      name: title,
      url: route.path,
    },
  ];
  const ogDescription = jobItemRef.value.getDescription1();
  const ogImage = cmsStoryModelRef.value?.getAttribute('ogImage');
  const headObject = getHeadObject({
    title,
    description,
    isIndexable,
    canonical,
    alternates,
    ogDescription,
    ogImage,
  });
  // @ts-ignore
  headObject.script = [getJobSchema(jobItemRef.value)];
  return { ...headObject, ...getBreadcrumbSchema(breadcrumbs) };
});

const headerStickyConfig: ObserveScrollConfig = {
  calculateActionPositions: (element) => {
    const stickyStartPosition = element?.offsetTop;
    const actionPositionNotSticky: ActionPosition = {
      onEnter: () => {
        isHeaderStickyRef.value = false;
        isHeaderPrepareStickyRef.value = false;
      },
      positionStart: 0,
      positionEnd: stickyStartPosition,
    };

    const actionPositionSticky: ActionPosition = {
      onEnter: () => {
        isHeaderPrepareStickyRef.value = true;
        isHeaderStickyRef.value = true;
      },
      // @ts-ignore
      positionStart: stickyStartPosition,
    };

    const actionPositionScrollUp: ActionPosition = {
      onEnter: () => {
        isHeaderStickyRef.value = false;
        isHeaderPrepareStickyRef.value = true;
      },
      // @ts-ignore
      positionStart: stickyStartPosition,
      scrollDirection: constants.SCROLL_DIRECTION_UP,
    };

    return [actionPositionNotSticky, actionPositionSticky, actionPositionScrollUp];
  },
};

function fixedEncodeURIComponent(str: string) {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => '%' + c.charCodeAt(0).toString(16));
}

// taken from https://github.com/bradvin/social-share-urls/blob/master/code/javascript/javascript/social-share-media.js
function getMediaSiteLinks(args: any) {
  const url = fixedEncodeURIComponent(args.url);
  const title = fixedEncodeURIComponent(args.title);

  return {
    email: 'mailto:?subject=' + title + '&body=' + url,
    facebook: 'https://www.facebook.com/sharer.php?u=' + url,
    linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=' + url,
    twitter: 'https://twitter.com/intent/tweet?url=' + url + '&text=' + title + '&via=&hashtags=',
    whatsapp: 'https://api.whatsapp.com/send?text=' + title + '%20' + url,
  };
}

function toggleShare() {
  showShareRef.value = !showShareRef.value;
}

function closeTogleShare() {
  showShareRef.value = false;
}

function handleCopyLink() {
  navigator.clipboard.writeText(fullPath);
}

function trackJobPostClick(eventName: string, parameters: Array<any>) {
  $gtm.trackEvent({
    eventName,
    parameters,
  });
}

$storyblokLivePreview.initStoryListener('Storyblok', cmsStoryModelRef);

useHead(metaData);
</script>

<template>
  <div
    :class="[
      'job-post',
      'transition',
      {
        'job-post--sticky-header-prepare': isHeaderPrepareStickyRef,
        'job-post--sticky-header': isHeaderStickyRef,
      },
      ...classNameRef,
    ]"
    @click="closeTogleShare"
  >
    <MopPageLoad :class="classNameRef" />

    <div class="transition-content">
      <MopCmsContentElementsSlot
        v-if="jobItemHeaderElements"
        id="job-post"
        class="job-post__content"
        :elements="jobItemHeaderElements"
      />
      <div class="job-post__head-sticky">
        <div class="job-post__head-wrap">
          <NuxtLink class="job-post__headline-logo-link" :to="$mopI18n.localePath('')" no-prefetch>
            <SVGMopLogoSignet width="30" height="30" />
          </NuxtLink>
          <div class="job-post__headline-wrap">
            <h1 class="job-post__headline">
              {{ jobItemRef.getTitle() }}
            </h1>
            <div class="job-post__subline">
              <div class="job-post__subline-text">
                {{ cmsStoryModelRef.getAttribute('sublineStartDate') }}:
                {{
                  jobItemRef.getFutureLocaleStartDate() || cmsStoryModelRef.getAttribute('sublineStartDateImmediately')
                }}
                - {{ jobItemRef.getScope() }} - {{ jobItemRef.getDepartment() }} - {{ jobItemRef.getFullAddress() }}
              </div>
            </div>
            <div class="job-post__apply-top-wrap--desktop-only job-post__apply-top-wrap">
              <NuxtLink
                :to="$mopI18n.localePath(`${$urls.CAREER_APPLY_PAGE}/${$route.params.jobId}`)"
                class="job-post__apply-top"
                :style="{ '--bg-color': $mopConfig.getApplyNowButtonColor() }"
                no-prefetch
                @click="trackJobPostClick('apply_now', ['click', 'intern'])"
              >
                {{ $mopI18n.t('locale.jobs.apply') }}
              </NuxtLink>
            </div>
          </div>
          <div class="job-post__headline-actions">
            <div class="job-post__share-wrap">
              <button class="job-post__share" @click.stop.prevent="toggleShare">
                <SVGShare />
              </button>
              <button class="job-post__wishlist" @click.stop.prevent="() => toggleWishlistItem(jobItemRef)">
                <SVGHeart v-if="!wishlistItemExists(jobItemRef.getId())" width="27" height="25" />
                <SVGHeartFilled v-else width="27" height="25" />
              </button>

              <div
                class="job-post__share-list"
                :class="{
                  'job-post__share-list--visible': showShareRef,
                }"
                @click.stop
              >
                <a
                  v-for="platform in Object.keys(socialMediaLinksRef)"
                  :key="platform"
                  :href="socialMediaLinksRef[platform]"
                  target="_blank"
                  class="job-post__share-link"
                  rel="noopener noreferrer"
                  @click="
                    trackJobPostClick('vacancy_social_share', [
                      'share',
                      $gtm.getCurrentPageType(),
                      platform,
                      jobItemRef.getTitle(),
                    ])
                  "
                >
                  {{ platform }}
                </a>
                <button class="job-post__share-link" @click="handleCopyLink">
                  {{ 'copy link' }}
                </button>
              </div>
            </div>
            <div class="job-post__apply">
              <NuxtLink
                :to="$mopI18n.localePath(`${$urls.CAREER_APPLY_PAGE}/${$route.params.jobId}`)"
                class="job-post__apply-now"
                :style="{ '--bg-color': $mopConfig.getApplyNowButtonColor() }"
                no-prefetch
                @click="trackJobPostClick('apply_now', ['click', 'intern'])"
              >
                {{ $mopI18n.t('locale.jobs.apply') }}
              </NuxtLink>
              <!--
                  <button
                    class="job-post__apply-external"
                    @click="$gtm.trackEvent({
                      eventName: 'apply_now',
                      parameters: ['click', 'linkedin']
                    });"
                  >
                    {{ 'LN' }}
                  </button>
                  <button
                    class="job-post__apply-external"
                    @click="$gtm.trackEvent({
                      eventName: 'apply_now',
                      parameters: ['click', 'xing']
                    });"
                  >
                    {{ 'XN' }}
                  </button>
    -->
            </div>
          </div>
        </div>
      </div>

      <div v-observe-scroll="headerStickyConfig" class="job-post__section">
        <h3 v-html="cmsStoryModelRef.getAttribute('headlineWhoWeAre')" />
        <div v-if="jobItemRef.getWhoWeAre()" id="about-us" class="job-post__text" v-html="jobItemRef.getWhoWeAre()" />
        <MopCmsContentElementsSlot
          v-else
          id="about-us"
          class="job-post__content"
          :elements="cmsStoryModelRef.getContentElements('whoWeAre')"
        />
      </div>

      <div class="job-post__section">
        <h3 v-html="cmsStoryModelRef.getAttribute('headlineWhatYouCanCreate')" />
        <div class="job-post__text" v-html="jobItemRef.getDescription1()" />
      </div>

      <div class="job-post__section">
        <h3 v-html="cmsStoryModelRef.getAttribute('headlineWhoYouAre')" />
        <div class="job-post__text" v-html="jobItemRef.getDescription2()" />
      </div>

      <div class="job-post__section">
        <h3 v-html="cmsStoryModelRef.getAttribute('headlineJobBenefits')" />
        <JobBenefits :elements="cmsStoryModelRef.getContentElements('benefits')" :job="jobItemRef" />
      </div>

      <div class="job-post__section job-post__section--believe">
        <MopCmsContentElementsSlot
          id="what-we-believe-in"
          class="job-post__content"
          :elements="cmsStoryModelRef.getContentElements('whatWeBelieveIn')"
        />
        <SVGMopLogo class="job-post__mop-logo" width="190" height="40" />
      </div>

      <div class="job-post__section">
        <h3 v-html="cmsStoryModelRef.getAttribute('headlineHrContact')" />
        <p>
          {{ jobItemRef.getHrName() }} {{ cmsStoryModelRef.getAttribute('labelHrName') }}<br />
          {{ cmsStoryModelRef.getAttribute('labelHrContact') }}
          <a :href="`mailto:${jobItemRef.getHrMail()}`">
            {{ jobItemRef.getHrMail() }}
          </a>
        </p>
      </div>

      <div class="job-post__section job-post__section--hr-contact">
        <MopCmsContentElementsSlot
          id="hr-contact-section"
          :elements="cmsStoryModelRef.getContentElements('hrContact')"
        />
      </div>

      <div class="job-post__section">
        <MopCmsContentElementsSlot
          id="career-blog"
          class="job-post__content"
          :elements="cmsStoryModelRef.getContentElements('careerBlog')"
        />
      </div>

      <div class="job-post__apply-bottom">
        <NuxtLink
          :to="$mopI18n.localePath(`${$urls.CAREER_APPLY_PAGE}/${$route.params.jobId}`)"
          class="job-post__apply-now-bottom"
          :style="{ '--bg-color': $mopConfig.getApplyNowButtonColor() }"
          no-prefetch
          @click="trackJobPostClick('apply_now', ['click', 'intern'])"
        >
          {{ $mopI18n.t('locale.jobs.apply') }}
        </NuxtLink>
      </div>
      <JobAudio :elements="cmsStoryModelRef.getContentElements('audios')" :job="jobItemRef" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.job-post {
  padding: 0 $global-padding;
}

.job-post__head-wrap {
  margin: $space15 0 0;
  padding-bottom: $space10;
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s ease;

  @include apply-upto(small) {
    flex-direction: column;
  }
}

.job-post__content {
  margin-left: -$global-padding;
  margin-right: -$global-padding;
}

.job-post__headline {
  @include text-style(strong);

  font-size: 40px;
  line-height: 40px;
  margin: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include apply-upto(medium) {
    font-size: 22px;
    line-height: 22px;
  }
}

.job-post__headline-logo-link {
  display: none;
}

.job-post__subline {
  margin: $space5 0 0;
  display: flex;
  justify-content: space-between;
}

.job-post__headline-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  @include apply-upto(small) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: $space20;
  }
}

.job-post__subline-text {
  @include text-style(strong);

  font-size: 14px;
  line-height: 14px;
}

.job-post__apply {
  display: none;
  margin-top: $space30;

  @include apply-upto(small) {
    display: flex;
    margin: 0;
    order: -1;
  }
}

.job-post__apply-now,
.job-post__apply-top,
.job-post__apply-now-bottom,
.job-post__apply-external {
  @include text-style(strong);

  background: var(--bg-color, $black);
  color: $white;
  text-transform: uppercase;
  padding: 16px $space20;
  margin: 0 0 0 $space10;
  border: 0;
  line-height: 18px;
  white-space: nowrap;

  @include apply-upto(small) {
    line-height: 15px;
    margin: 0;
  }
}

.job-post__apply-now-bottom {
  margin: 0;
}

.job-post__apply-top-wrap {
  margin: $space30 0 0;
}

.job-post__wishlist {
  background: $white;
  height: 47px;
  width: 47px;
  bottom: 0;
  border: 1px solid $black;
  line-height: 0;
  cursor: pointer;
  color: $black;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.job-post__share {
  background: $white;
  height: 47px;
  width: 47px;
  bottom: 0;
  border: 1px solid $black;
  border-right: 0;
  line-height: 0;
  cursor: pointer;
  color: $black;
  padding: 0;
  margin: 0;

  svg {
    width: 30px;
  }
}

.job-post__apply-external {
  padding: 8px 6px 8px 7px;
}

.job-post__section {
  margin: $space30 0 0;
  &:deep(h3) {
    @include text-style(strong);

    margin: 0 0 $space15;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
  }

  &:deep(p) {
    @include text-style(common);

    margin-bottom: 0;
  }
}

.job-post__section--hr-contact {
  margin: 0 -10px;
}

.job-post__section--believe {
  display: grid;
  grid-template-columns: 1fr 400px;

  @include apply-upto(medium) {
    grid-template-columns: 1fr 300px;
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }

  &:deep(p) {
    @include text-style(strong);

    font-size: 16px;
    line-height: 18px;
    color: $surface-gray;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  svg {
    justify-self: center;
    align-self: center;

    @include apply-upto(small) {
      justify-self: start;
      margin: $space20 0 $space10;
    }
  }
}

.job-post__text {
  @include text-style(common);

  // comming from api
  &:deep(ul) {
    padding-left: $space20;
  }
  &:deep(li) {
    @include text-style(common);

    list-style: disc;
    margin-left: $space5;
    margin-top: $space5;
    padding-left: $space10;
  }
}

.job-post__share-wrap {
  display: flex;
}

.job-post__share-list {
  position: absolute;
  width: 180px;
  border: 1px solid $black;
  top: 50px;
  right: 0;
  font-size: 13px;
  line-height: 13px;
  padding: $space20 $space10;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    transform: rotate(90deg);
  }

  &::before {
    top: -18px;
    right: 60px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid $black;
  }

  &::after {
    top: -15px;
    right: 61px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $white;
  }
}

.job-post__share-list--visible {
  @include z(global, content);

  visibility: visible;
  opacity: 1;
  background: $white;
}

.job-post__share-link {
  @include link-not-underlined(5px);
  @include text-style(strong);

  text-transform: uppercase;
}

.job-post--sticky-header-prepare {
  .job-post__head-sticky {
    padding-bottom: 100px;
  }

  .job-post__share-list {
    &::before {
      right: 38px;
    }

    &::after {
      right: 39px;
    }
  }

  .job-post__apply-top-wrap {
    display: none;
  }

  .job-post__share,
  .job-post__wishlist {
    height: 29px;
    width: 29px;

    svg {
      width: unset;
    }
  }

  .job-post__apply-now {
    padding: 7px $space15;
    line-height: 15px;
  }

  .job-post__head-wrap {
    @include z(layers, superbanner);

    transform: translateY(-20px);
    height: $header-height;
    top: -$header-height;
    width: auto;
    left: 0;
    right: 0;
    position: fixed;
    background: $white;
    padding: 0 $global-padding;
    margin: 0;
    align-items: center;

    @include apply-only-touch-device {
      height: $mobile-header-height;
      top: -$mobile-header-height;
      justify-content: space-between;
      flex-direction: row;
    }

    @include apply-upto(medium) {
      height: $mobile-header-height;
      top: -$mobile-header-height;
      justify-content: flex-end;
      flex-direction: row;
    }
  }

  .job-post__subline-text {
    @include text-style(common);
  }

  .job-post__headline,
  .job-post__subline-text {
    font-size: 13px;
    line-height: 13px;
  }

  .job-post__headline-actions {
    flex-direction: row;
    align-items: center;

    @include apply-upto(medium) {
      margin: 0;
    }
  }

  .job-post__apply {
    display: flex;
    margin: 0 $space10;
    order: -1;
  }

  .job-post__headline-wrap {
    padding-left: 50px;

    @include apply-upto(medium) {
      display: none;
    }
  }

  .job-post__headline-logo-link {
    position: absolute;
    left: $global-padding;
    display: block;

    @include apply-only-touch-device {
      top: 12px;
    }
  }
}

.job-post--sticky-header-prepare.job-post--sticky-header {
  .job-post__head-wrap {
    transform: translateY(100%);
  }
}

.job-post__apply-bottom,
.job-post__apply-top {
  margin: $space30 0 0;
}

.job-post__apply-top-wrap--desktop-only {
  @include apply-upto(small) {
    display: none;
  }
}
</style>
